/* Fira Sans Thin */
@font-face {
    font-family: "Lato";
    src: url('fonts/Lato/Lato-Thin.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url('fonts/Lato/Lato-ThinItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

/* Fira Sans Light */
@font-face {
    font-family: "Lato";
    src: url('fonts/Lato/Lato-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url('fonts/Lato/Lato-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

/* Fira Sans Normal */
@font-face {
    font-family: "Lato";
    src: url('fonts/Lato/Lato-Regular.ttf') format('truetype');
    font-weight: Normal;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url('fonts/Lato/Lato-Italic.ttf') format('truetype');
    font-weight: Normal;
    font-style: italic;
}

/* Fira Sans Bold */
@font-face {
    font-family: "Lato";
    src: url('fonts/Lato/Lato-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url('fonts/Lato/Lato-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

/* Fira Sans Black */
@font-face {
    font-family: "Lato";
    src: url('fonts/Lato/Lato-Black.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url('fonts/Lato/Lato-BlackItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}
