
//*, *::before, *::after {
//    box-sizing: border-box;
//}
//
//
//* {
//    font-family: 'Carlito', sans-serif;
//    color: $dark;
//    margin: 0;
//}
//
//
//html, body {
//    font-size: 1.2rem;
//    height: 100%;
//}
//
//
//body {
//    line-height: 1.5;
//    -webkit-font-smoothing: antialiased;
//}
//
//
//img, picture, video, canvas, svg {
//    display: block;
//    max-width: 100%;
//}

html {
    scroll-behavior: smooth;
}



* {
    font-family: 'Lato', sans-serif;
    color: $dark;
    margin: 0;
}


html, body {
    background-color: $white;
    height: 100%;
}

.side-padding {
    padding-left: 7%;
    padding-right: 8.5%;
}

.careers {

    .careers__text {

        h1 {
            color: $text-gray;
        }

        .careers__text__body {
            .apply-link > a:hover {
                i {
                    background-color: $light-green;
                    color: $white;
                }
            }
        }

    }

}

.the-team {

    .VueCarousel-navigation {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .VueCarousel-navigation-next, .VueCarousel-navigation-prev {
            transform: none;
        }

        .VueCarousel-navigation-button {
            position: relative;
            padding: 0;
            margin-left: 1% !important;
        }

        .navigation-arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: $gray;

            &:hover, &:focus {
                background-color: $text-gray;
            }

        }

    }
}

.team-card {
    .team-card_info {
        .team-card_info__jobPosition {
            color: $text-gray;
        }
    }

}

.splash-section {

    .splash-section__text {

        p {
            color: $white;
        }

        .splash-section__green-text {
            color: $light-green;
        }

    }

}

.contact-us__body {
    background-color: $light-gray;

    .contact-us__text__header {
        h2 {
            color: $dark-gray;
        }
        h2.bold-text {
            color: $dark;
        }

    }

    .contact-us__text__body {

        .address > .address-entry p {
            color: $dark-gray;
        }

    }
}

.contact-us__form-wrapper {
    form {
        input, textarea {
            border: 0;
            border-bottom: 3px solid $gray;
            background: transparent;
            margin-bottom: 5%;
            padding-bottom: 1%;
            font-size: 1.25em;

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: $text-gray;
            }


        }

        input {
            padding-top: 30px;
        }

        textarea {
            padding: 15px 0;

            &::placeholder {
                padding-top: 3%;
            }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }

        .submit-buttons {

            button.submit {
                background-color: $dark;
                color: $white;

                &:hover {
                    background-color: $light-green;
                }

                &:disabled {
                    background-color: $gray;
                }

            }
        }

    }
}


.scroll-to-top {
    background-color: $orange;
    i {
        color: $white;
    }
}

.footer {
    background-color: $dark;

    .footer__text {
        h4 {
            color: $text-gray;
        }
    }

    .footer__social-media-icons {
        .icon {
            color: $dark;
            background-color: $light-gray;
        }
    }

}

